.progress {
  border-radius: 100px;
  background: repeating-linear-gradient(to right, #d1ecf1, #d1ecf1 25%, #fff 25%, #fff 25.5%);
  position: relative;
}
.progress:after {
  content: '';
  background: repeating-linear-gradient(to right, transparent, transparent 25%, #fff 25%, #fff 25.5%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.progress >>> .progress-bar {
  background: #417bff;
  font-size: 0;
}
.pendingPlan {
  opacity: 0.7;
  pointer-events: none;
}
/*# sourceMappingURL=src/components/pages/dashboard/PlanPage.css.map */