.code-box {
  background-color: #e8f2fa;
  padding: 10px 30px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
/*# sourceMappingURL=src/components/pages/dashboard/components/Enable2FaForm.css.map */